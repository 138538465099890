<template>
  <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <title/>
    <g id="Lock">
      <path class="cls-1" d="M19,14H13a1,1,0,0,1-1-1V10a4,4,0,0,1,8,0v3A1,1,0,0,1,19,14Zm-5-2h4V10a2,2,0,0,0-4,0Z"/>
      <rect class="cls-2" height="14" rx="3" ry="3" width="14" x="9" y="12"/>
      <path class="cls-1" d="M18,18a2,2,0,1,0-3,1.72V21a1,1,0,0,0,2,0V19.72A2,2,0,0,0,18,18Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconLock"
}
</script>

<style scoped>
.cls-1 {
  fill: #cecece;
}

.cls-2 {
  fill: #ffffff;
}
</style>
