<template>
  <div class="dashboard">
    <div class="logged" v-if="showLoggedInType">
      <div class="logged-selector-wrapper">
        <h2 class="logged-selector__header">Select licenses and marketplaces</h2>
        <div class="logged-selector__body">
          <div class="logged-selector__body-marketplaces">
            <VnSelect
              v-model="selectedMarketPlace"
              :disabled="false"
              :label="$t('Marketplaces')"
              :options="marketPlaces"
              :readonly="false"
              icon="search"
              maxHeight="300px"
              placeholder="Type or scroll and choose"
              searchable
              textBy="name"
            />
          </div>
          <div class="logged-selector__body-licenses">
            <VnSelect
              v-model="selectedLicenses"
              :disabled="false"
              :label="$t('Licenses')"
              :multi-cleanable="true"
              :options="licenses"
              position="bottom"
              icon="search"
              multiselect-placeholder-shown
              multiple
              :readonly="false"
              maxHeight="300px"
              placeholder="Type or scroll and choose"
              searchable
              textBy="name"
            />
          </div>
          <div class="logged-selector__body-groups" v-if="statisticsGroups.length > 1">
            <VnSelect
              v-model="selectedGroups"
              :disabled="false"
              :label="$t('Statistics groups')"
              :options="statisticsGroups"
              :readonly="false"
              icon="search"
              maxHeight="300px"
              placeholder="Type or scroll and choose"
              searchable
              textBy="name"
            />
          </div>
        </div>
        <div class="logged-selector__button">
          <va-button @click="submit">Confirm</va-button>
        </div>
      </div>
    </div>
    <div id="amazon-root" v-else>
      <p class="amazon-login-title">Authorize with amazon seller partner</p>
      <a
        class="amazon-login-link"
        href="https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.a7cc103d-1f2c-451a-b2e0-c59456623aad&state=stateexample&version=beta">
        <IconLock class="amazon-login-link__lock"/>
        Login with amazon
      </a>
      <p class="amazon-login-subtitle">Logged in as {{ displayedName }}</p>
    </div>
  </div>
</template>

<script>
import IconLock from "@/components/ui/icons/IconLock.vue";
import {mapState} from "vuex";
import API from "@/services/API/API.js";
import {showToastError} from "@/services/Helpers/HelperToast.js";

export default {
  name: "Amazon",
  components: {
    IconLock,
    VnSelect: () => import('../ui/vn-select'),
    VaButton: () => import('vuestic-ui/src/components/vuestic-components/va-button/VaButton'),

  },
  computed: {
    ...mapState(['currentUserProfilePermissions']),
    displayedName() {
      return JSON.parse(localStorage.getItem('ttlUserInfo'))?.username
    },
    licenseHeader () {
      if (this.selectedLicenses.length) {
        return this.selectedLicenses.length > 1 ? `${this.selectedLicenses[0].name}, +${this.selectedLicenses.length - 1}` : this.selectedLicenses[0].name
      } else {
        return 'Select one or more licenses'
      }
    },
    marketPlaceHeader() {
      if (this.selectedMarketPlace) {
        return this.selectedMarketPlace.country
      } else {
        return 'Select marketplace'
      }
    }
  },
  data() {
    return {
      sellingPartnerId: null,
      spapiOauthCode: null,
      state: null,
      showLoggedInType: false,
      licenses: [],
      selectedLicenses: [],
      marketPlaces: [],
      selectedMarketPlace: [],
      statisticsGroups: [],
      selectedGroups: [],
      showLicenses: false,
      showMarketPlaces: true,
    }
  },
  watch: {
    '$route.query'(val) {
      if (val.state && val.spapi_oauth_code && val.selling_partner_id) {
        this.sellingPartnerId = val.selling_partner_id
        this.spapiOauthCode = val.spapi_oauth_code
        this.state = val.state
      }
    },
  },
  mounted() {
    if (this.$route.query.amazon_callback_uri && this.$route.query.amazon_state && this.$route.query.selling_partner_id) {
      window.location.href = `${this.$route.query.amazon_callback_uri}?redirect_uri=https://my.24ttl.stream/panel/amazon&amazon_state=${this.$route.query.amazon_state}&state=${localStorage.getItem('accessToken')}`
    }
    if (this.$store.state.amazonRedirectData.amazonCallbackUri && this.$store.state.amazonRedirectData.sellingPartnerId && this.$store.state.amazonRedirectData.amazonState) {
      window.location.href = `${this.$store.state.amazonRedirectData.amazonCallbackUri}?redirect_uri=https://my.24ttl.stream/panel/amazon&amazon_state=${this.$store.state.amazonRedirectData.amazonState}&state=${localStorage.getItem('accessToken')}`
    }
    if (this.$route.query.state && this.$route.query.spapi_oauth_code && this.$route.query.selling_partner_id) {
      this.sellingPartnerId = this.$route.query.selling_partner_id
      this.spapiOauthCode = this.$route.query.spapi_oauth_code
      this.state = this.$route.query.state
      this.showLoggedInType = true
      this.licenses = JSON.parse(localStorage.getItem('ttlUserInfo'))?.licenses

      this.statisticsGroups = JSON.parse(localStorage.getItem('ttlUserInfo')).statisticsGroups
      if (this.statisticsGroups.length === 1) {
        this.selectedGroups = this.statisticsGroups[0]
      }
      API.APIGet(`${process.env.VUE_APP_API_URL}/amazon/marketplaces`, {}, (res) => {
        this.marketPlaces = res ? res.map(r => {
          return {
            id: r.marketplaceId,
            name: r.country,
          }
        }) : [];
      })
    }
  },
  methods: {
    async submit() {
      if (this.selectedLicenses.length && this.selectedMarketPlace && this.selectedGroups) {
        let selectedLicenseIds = this.selectedLicenses.map(l => {
          return l.id
        })
        let selectedMarketPlaceId = this.selectedMarketPlace.id
        let selectedGroupId = this.selectedGroups.id
        await API.APIPost(`${process.env.VUE_APP_API_URL}/authorize-amazon-retailer`, {
          awsPartnerId: this.sellingPartnerId,
          awsAuthCode: this.spapiOauthCode,
          licenseIds: selectedLicenseIds,
          awsMarketplaceId: selectedMarketPlaceId,
          statisticsGroupId: selectedGroupId
        }, () => {
          window.location.href = '/panel/dashboard';
        }, (e) => {
          if (e.response.status == 400) {
            showToastError('Your aws token was expired, please try again', this.$toast)
          }
        })
      } else {
        if (this.statisticsGroups.length > 1) {
          showToastError('Please select marketplace, statistics group and at least one license', this.$toast)
        } else {
          showToastError('Please select marketplace, and at least one license', this.$toast)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.amazon-login {
  &-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &-link {
    display: flex;
    border-radius: 15px;
    width: fit-content;
    padding: 10px 15px 10px 10px;
    align-items: center;
    justify-content: center;
    background-color: #0e6ae0;
    color: #ffffff;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 24px;

    &__lock {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
  }

  &-subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

.vac-card {
  max-width: unset;
  width: fit-content !important;
}

.logged {
  &-selector {
    &-wrapper {
      min-width: 500px;
    }
    &__header {
      font-size: 21px;
      margin-bottom: 10px;
    }
    &__body {
      display: flex;
      width: 100%;

      &-licenses {
        width: 50%;
        &-top {
          padding: 10px 5px;
          background-color: #0e6ae0;
          color: #ffffff;
          cursor: pointer;
        }
        &-content {
          max-height: 500px;
          min-width: 400px;
          overflow: auto;
          transition-property: max-height;
          transition-timing-function: linear;
          transition-duration: 0.3s;
          &--closed {
            max-height: 0;
          }
        }

        &-item {
          cursor: pointer;
          padding: 5px 10px;

          &--selected {
            background-color: #1e1e1e;
            color: #ffffff;
          }
        }
      }

      &-marketplaces {
        margin-right: 10px;
        width: 50%;
        &-top {
          padding: 10px 5px;
          background-color: #0e6ae0;
          color: #ffffff;
          cursor: pointer;
        }
        &-content {
          max-height: 500px;
          min-width: 400px;
          overflow: auto;
          transition-property: max-height;
          transition-timing-function: linear;
          transition-duration: 0.3s;
          &--closed {
            max-height: 0;
          }
        }

        &-item {
          cursor: pointer;
          padding: 5px 10px;

          &--selected {
            background-color: #1e1e1e;
            color: #ffffff;
          }
        }
      }
    }
    &__button {
      width: 100%;
      margin-top: 20px;
      display: flex;
      * {
        font-family: "Source Sans Pro", "Arial", sans-serif !important;
      }
    }
  }
}
</style>
